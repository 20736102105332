import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-charcoal-black pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-charcoal-black fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold">Car Lounge</h4>
              <div className="mt-6 lg:mb-0 mb-6">
                <p className="font-bold">Showroom</p>
                <p>
                  Utmed E4:an i naturskön miljö är vi stolta över att presentera
                  vårt showroom, där är du alltid välkommen in i vår Lounge, ta
                  en god kopp kaffe och njuta av trevliga objekt.
                </p>
              </div>
              <div className="mt-6 lg:mb-0 mb-6">
                <p className="font-bold">Öppettider i vårat showroom </p>
                <p>Måndag - Fredag 10:00 - 18:00 </p>
                <p>Lördag 11:00 - 14:00 </p>
                <p>Övriga tider: Tidsbokning </p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-sm font-semibold mb-2">
                    Snabbval
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link className="font-semibold block pb-2 text-sm" to="/">
                        Startsida
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="font-semibold block pb-2 text-sm"
                        to="/om-oss"
                      >
                        Om oss
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <span className="block text-sm font-semibold mb-2">
                    Följ oss
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <div className="mt-6 lg:mb-0 mb-6">
                        <button
                          className="bg-jet-black shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                          type="button"
                        >
                          <a href="" target="_blank">
                            <i className="fab fa-facebook"></i>
                          </a>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className="mt-6 lg:mb-0 mb-6">
                        <div className="mt-6 lg:mb-0 mb-6">
                          <button
                            className="bg-jet-black shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                            type="button"
                          >
                            <a href="" target="_blank">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="mt-6 lg:mb-0 mb-6">
                        <button
                          className="bg-jet-black shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                          type="button"
                        >
                          <a href="" target="_blank">
                            <i className="fab fa-tiktok"></i>
                          </a>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 divider" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm font-semibold py-1">
                Copyright © {new Date().getFullYear()} by{" "}
                <a href="https://www.brave-consulting.se" className="">
                  Brave Consulting AB
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
