import { createRoot } from "react-dom/client";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

import Single from "views/Single.js";
import About from "components/About.js";
import Index from "views/Index.js";
import boatImage from "assets/produkter/båtkapell-kapell-båt-tillverkning.jpeg";
import specialImage from "assets/tjänster/specialkapell-tillvekning-lampkapell.jpeg";

const container = document.getElementById("root");
const root = createRoot(container);

const boatContent = {
  title: "Båtkapell",
  description:
    "Vi specialiserar oss på att skapa skräddarsydda båtkapell, designade för att stå emot tuffa väderförhållanden och ge maximalt skydd.",
  image: boatImage,
  metaTitle: "Båtkapell | Köp måttbeställda kapell för båtar",
  contentTitle: "Skräddarsydda båtkapell för alla båtmodeller ",
  contentDescription:
    "Vi erbjuder högkvalitativa, skräddarsydda båtkapell som passar perfekt till din båt, oavsett modell. \n\nVåra kapell är tillverkade av de bästa materialen på marknaden och designade för att hålla länge. \n\nGenom att ta mått direkt på din båt säkerställer vi en perfekt passform som skyddar din båt mot väder och vind. \n\nVåra båtkapell är inte bara funktionella utan också estetiskt tilltalande, vilket gör att din båt ser fantastisk ut samtidigt som den skyddas. Vi erbjuder ett brett utbud av färger och material så att du kan välja det som bäst passar din stil och dina behov.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa kapellösningen för din båt. Vi diskuterar dina preferenser och ger råd om de bästa alternativen baserat på vår erfarenhet.",
  contentHowStep2:
    "Sedan tar noggranna mått på din båt och hjälper dig att välja rätt material och färg som passar både din båt och dina preferenser. Vi erbjuder ett brett sortiment av material som är både hållbara och estetiskt tilltalande. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt kapell med precision och noggrannhet. Vi använder avancerad teknik och traditionellt hantverk för att skapa ett kapell som inte bara ser bra ut utan också håller i många år. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

const aboutContent = {
  title: "Om oss",
  description:
    "Vi är experter på att skapa skräddarsydda specialkapell som möter de mest unika och krävande behoven.",
  image: specialImage,
  metaTitle: "Specialkapell | Köp specialanpassade kapell efter era behov",
  contentTitle: "Skräddarsydda specialkapell",
  contentDescription:
    "Våra specialkapell är designade för att möta de mest unika och krävande behoven. \n\nMed innovativa designkoncept och högkvalitativa material levererar vi anpassade lösningar som kombinerar funktionalitet, hållbarhet och stil. \n\nVi erbjuder ett brett utbud av material och färger för att passa dina specifika behov och preferenser.",
  contentHowTitle: "Så här går det till",
  contentHowStep1:
    "Vi börjar med att förstå dina specifika behov och önskemål. Genom att lyssna på dina krav kan vi rekommendera den bästa lösningen för ditt specialkapell.",
  contentHowStep2:
    "Vi tar noggranna mått och hjälper dig att välja rätt material och färg som passar dina behov. Våra experter ser till att varje detalj är perfekt för att säkerställa en optimal passform och lång livslängd.",
  contentHowStep3:
    "Våra erfarna hantverkare syr ditt specialkapell med precision och noggrannhet. Varje kapell genomgår noggranna kvalitetskontroller för att säkerställa att det uppfyller våra höga standarder.",
};

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <Route
        path="/produkter/båtkapell"
        exact
        render={(props) => <Single {...props} content={boatContent} />}
      />
      <Route
        path="/kontakta-oss"
        exact
        render={(props) => <About {...props} content={aboutContent} />}
      />
      <Route
        path="/formedling"
        exact
        render={(props) => <About {...props} content={aboutContent} />}
      />
      <Route
        path="/salj-till-oss"
        exact
        render={(props) => <About {...props} content={aboutContent} />}
      />
      <Route
        path="/till-salu"
        exact
        render={(props) => <About {...props} content={aboutContent} />}
      />
      <Route
        path="/om-oss"
        exact
        render={(props) => <About {...props} content={aboutContent} />}
      />
      <Route path="/" exact component={Index} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
);
