import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let errors = {};

    if (!formData.name) {
      errors.name = "Namn är obligatoriskt";
    }
    if (!formData.email) {
      errors.email = "Email är obligatoriskt";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email-adressen är ogiltig";
    }
    if (!formData.subject) {
      errors.subject = "Ämne är obligatoriskt";
    }
    if (!formData.message) {
      errors.message = "Meddelande är obligatoriskt";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      // Submit the form
      fetch("https://formspree.io/XXXXXXX", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            setIsSubmitted(true);
            setFormData({
              name: "",
              email: "",
              subject: "",
              message: "",
            });
            setErrors({});
          } else {
            alert(
              "Oops! Det uppstod ett problem med att skicka ditt formulär."
            );
          }
        })
        .catch((error) => {
          alert("Oops! Det uppstod ett problem med att skicka ditt formulär.");
        });
    } else {
      setErrors(validationErrors);
    }
  };

  const handleReset = () => {
    setIsSubmitted(false);
  };

  return (
    <div>
      {isSubmitted ? (
        <div className="max-w-lg mx-auto p-8 bg-white text-green-700 shadow-md rounded-lg flex flex-col items-center">
          <p className="text-center">
            Tack för ditt meddelande! Vi återkommer till dig så snart som
            möjligt.
          </p>
          <button
            onClick={handleReset}
            className="mt-8 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
          >
            Fler frågor?
          </button>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="max-w-lg mx-auto p-4 bg-white shadow-md rounded-lg"
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Namn
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
            {errors.name && (
              <p className="text-red-500 text-xs italic">{errors.name}</p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
            {errors.email && (
              <p className="text-red-500 text-xs italic">{errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="subject"
            >
              Ämne
            </label>
            <textarea
              name="subject"
              id="subject"
              rows="1"
              value={formData.subject}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            ></textarea>
            {errors.subject && (
              <p className="text-red-500 text-xs italic">{errors.subject}</p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="message"
            >
              Meddelande
            </label>
            <textarea
              name="message"
              id="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            ></textarea>
            {errors.message && (
              <p className="text-red-500 text-xs italic">{errors.message}</p>
            )}
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="mt-4 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-jet-black active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
            >
              Skicka
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
