import React from "react";
import { Link } from "react-router-dom";
import placeholder from "assets/img/placeholder.png";

export default function CarCard() {
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-charcoal-black transform hover:scale-105 ease-linear transition-all duration-150">
      <img
        alt="..."
        src={placeholder}
        className="w-full align-middle rounded-t-lg object-cover"
      />
      <blockquote className="relative p-8 mb-4">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 583 95"
          className="absolute left-0 w-full block h-95-px -top-94-px"
        ></svg>
        <div className="flex justify-between items-center">
          <h4 className="text-xl sm:text-l font-bold text-white">Porche 911</h4>
          <span className="text-xl sm:text-l font-bold text-champagne">
            699.000 kr
          </span>
        </div>
        <hr className="border-t-2 divider my-4" />

        <div className="container py-2 mx-auto text-white">
          <div className="flex flex-wrap">
            <div className="w-full flex-1">
              <div className="w-full sm:w-1/2 md:w-1/4 ">
                <p className="font-bold">Årsmodell</p>
                <p className="text-champagne-gold">2019</p>
              </div>
            </div>
            <div className="w-full  flex-1">
              <div className="w-full sm:w-1/2 md:w-1/4 ">
                <p className="font-bold">Miltal</p>
                <p className="text-champagne-gold">7 750 mil</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-2 mx-auto text-white">
          <div className="flex flex-wrap">
            <div className="w-full flex-1">
              <div className="w-full sm:w-1/2 md:w-1/4">
                <p className="font-bold">Färg</p>
                <p className="text-champagne-gold">Narvik Black</p>
              </div>
            </div>
            <div className="w-full flex-1">
              <div className="w-full sm:w-1/2 md:w-1/4">
                <p className="font-bold">Drivmedel</p>
                <p className="text-champagne-gold">Bensin</p>
              </div>
            </div>
          </div>
        </div>
      </blockquote>
    </div>
  );
}
