/*eslint-disable*/
import React from "react";
import { Helmet } from "react-helmet";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Contact from "components/Contact";
import Logo from "assets/img/logo3.png";
import aboutimg from "assets/img/tönnersa.jpg";
import kaka from "assets/img/bg1.jpg";
import andre from "assets/img/Andre.jpg";
import dan from "assets/img/Dan.jpg";

const About = () => {
  return (
    <>
      <div style={{ whiteSpace: "pre-line" }}>
        <Helmet>
          <title>Om oss | Car Lounge</title>
        </Helmet>
        <IndexNavbar fixed />
        <section
          className="header relative pt-16 px-6 items-center flex h-screen max-h-860-px bg-cover bg-center"
          style={{
            backgroundImage: `url(${kaka})`,
          }}
        >
          <div className="container md:w-6/12 lg:ml-24 bg-charcoal-black shadow-xl rounded-lg py-16 px-12 relative z-40">
            <div className="w-full flex flex-wrap text-center">
              <div className="sm:block flex flex-col">
                <h2 className="font-semibold text-4xl text-white">
                  Vilka är vi?
                </h2>
                <p className="pt-8">
                  Bakom Car Lounge hittar ni Dan och André som med över 30-års
                  erfarenhet av bilförsäljning har hjälpt tusentals
                  bilentusiaster att både köpa samt sälja sina bilar över hela
                  Europa. Hos oss får du inte bara personlig service i högsta
                  klass utan här hittar du också en plats där du kan utforska,
                  drömma och hitta den bil som passar just dig, alltid noggrant
                  genomgången samt väldokumenterad som en trygghet för alla
                  parter.
                </p>
                <p className="pt-8">
                  Varje gång vi säljer en bil gör vi det med ett stort
                  engagemang. Ibland söker vi upp kundens drömbil på
                  beställning, ibland står den redan i vår hall och väntar på
                  sin rätta ägare. Genom vår långa erfarenhet har vi byggt upp
                  ett brett kontaktnät i Europa. Detta blir allt viktigare då vi
                  ser att många bilar även hittar sina nya ägare även utanför
                  Sveriges gränser. Vi vill skilja oss från mängden och skapa
                  trygghet för dig som kund och värna om långvariga relationer.
                </p>
                <p className="pt-8">
                  Tack för att du väljer Car Lounge – vi ser fram emot att få
                  hjälpa dig hitta din nästa bil!
                </p>
              </div>
              <div className="text-center"></div>
            </div>
          </div>
        </section>

        <section className="overflow-hidden bg-charcoal-black">
          <div className="container mx-auto pb-32">
            <div className="flex flex-wrap justify-center">
              <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <img className="rounded-lg" src={andre} alt="..." />
                <h1 className="text-3xl mb-2 font-semibold leading-normal">
                  Andre
                </h1>
                <p className="pt-4">
                  André har sysslat med att köpa &amp; sälja i hela sitt liv,
                  han har arbetat dom senaste 20-åren med försäljning av
                  premiumbilar. För André är kundrelationen och arbetet med
                  bilar drivkraften i allt han gör.
                </p>
                <p className="pt-4">
                  - ”Med erfarenheten kan man utmana och utveckla bilbranschen
                  samtidigt som kunderna alltid är ens viktigaste ambassadörer” 
                </p>
                <p className="pt-4">
                  Med sin långa erfarenhet, sitt öga för detaljer och sin
                  passion för det han gör, ser André fram emot att välkomna
                  kunder till Car Lounge.
                </p>
              </div>
              <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <img className="rounded-lg" src={dan} alt="..." />
                <h1 className="text-3xl mb-2 font-semibold leading-normal">
                  Dan
                </h1>
                <p className="pt-4">
                  Dan har flera års erfarenhet av försäljning, senare åren inom
                  fordonsbranshen och har ett mycket stort intresse för bilar.
                  Dan har sedan många år drivit eget företag med goda referenser
                  inom bilvård.
                </p>
                <p className="pt-4">
                  Med sin glada och positiva personlighet så hjälper han dig att
                  göra ett köp som känns både roligt och tryggt hela vägen.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="overflow-hidden bg-gunmetal-gray">
          <div className="container mx-auto pb-32">
            <div className="flex flex-wrap justify-center">
              <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <h1 className="text-3xl mb-2 font-semibold leading-normal">
                  Hemleverans
                </h1>
                <p className="pt-4">
                  På Car Lounge strävar vi efter att göra bilköpet så bekvämt
                  och smidigt som möjligt för våra kunder. Därför erbjuder vi
                  dig alltid en videopresentation som ger dig en tydlig och
                  detaljerad bild av din nästa bil, oavsett var i landet du
                  befinner dig kan du få din bil levererad tryggt och säkert
                  direkt till din dörr.
                </p>
                <p className="pt-4">
                  Med vår hemleverans får du samma höga kvalitet och service som
                  vi står för – ända hem.
                </p>
              </div>
              <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <h1 className="text-3xl mb-2 font-semibold leading-normal">
                  Din trygghet
                </h1>
                <p className="pt-4">
                  På Car Lounge värdesätter vi kvalitet och trygghet för våra
                  kunder. Därför ingår en omfattande garanti i upp till 36
                  månader och 15000 mil när du köper bil av oss samt 1 månads
                  fri försäkring helt utan extra kostnad.
                </p>
                <p className="pt-4">
                  Vi vill att du ska känna dig trygg med ditt köp och njuta av
                  din bil utan bekymmer oavsett vart du bor i landet. Det är
                  vårt sätt att visa att vi står bakom våra bilar och din
                  köpupplevelse.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="overflow-hidden bg-jet-black">
          <div className="container mx-auto pb-32">
            <div className="flex flex-wrap justify-center">
              <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <h1 className="text-3xl mb-2 font-semibold leading-normal">
                  Finansiering
                </h1>
                <p className="pt-4">
                  På Car Lounge vill vi göra det enkelt och smidigt för dig att
                  finansiera din nya bil. Därför erbjuder vi flexibla
                  finansieringslösningar i samarbete med Nordea Finans samt
                  Santander Consumer bank.
                </p>
                <p className="pt-4">
                  Med förmånliga villkor och konkurrenskraftiga räntor hjälper
                  vi dig att hitta en lösning som passar dina behov och din
                  ekonomi. Låt oss göra ditt bilköp bekvämt och tryggt!
                </p>
              </div>
              <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <h1 className="text-3xl mb-2 font-semibold leading-normal">
                  Samarbetspartners
                </h1>
                <p className="pt-4">
                  Solid Garanti, Länsförsäkringar, Nordea Finans, Santander
                  Consumer bank.
                </p>
              </div>
            </div>
          </div>
        </section>

        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default About;
