import React from "react";
import ContactForm from "./ContactForm";

export default function Contact() {
  return (
    <>
      <section
        id="contact-section"
        className="relative py-20 bg-graphite-gray overflow-hidden"
      >
        <div className="container mx-auto pb-64">
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-comment text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                Kontakta oss
              </h3>

              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
                Vi ser fram emot att höra från dig! Oavsett om du har frågor,
                feedback eller bara vill säga hej, är du alltid välkommen att
                kontakta oss.
              </p>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
                Fyll i formuläret så återkommer vi till dig så snart som
                möjligt.
              </p>
              <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-white">
                Ha en fantastisk dag!
              </p>
              <ul className="list-none mt-6">
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3">
                        <i className="fas fa-map"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-white">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-white"
                        >
                          Adress
                        </a>
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3">
                        <i className="fas fa-mobile-screen"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-white">
                        <a href="tel:+460000">+46 (0)700 00 00 00</a>
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3">
                        <i className="fas fa-envelope"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-white">
                        <a href="mailto:" className="text-white">
                          kontakt@carlounge.se
                        </a>
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="pb-16 bg-jet-black relative pt-32">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-jet-black fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container px-6 mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg py-16 px-12 relative z-10">
            <div className="w-full text-center lg:w-8/12">
              <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  ⭐️
                </span>
              </p>
              <h3 className="font-semibold text-3xl">Varför välja oss?</h3>
              <ul className="list-none mt-6">
                <li className="py-3">
                  <div className="flex items-center">
                    <div>
                      <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                        <i className="fas fa-brain "></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-blueGray-500">
                        <strong>Erfarenhet:</strong> Med många års erfarenhet
                        inom branschen har vi den kunskap och expertis som krävs
                        för att leverera högkvalitativa kapell. Våra hantverkare
                        är skickliga och passionerade, och vi använder endast de
                        bästa materialen och teknikerna.
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                        <i className="fas fa-face-smile-beam "></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-blueGray-500">
                        <strong>Kundnöjdhet:</strong> Vi strävar alltid efter
                        att överträffa våra kunders förväntningar och leverera
                        produkter som de är 100% nöjda med. Vi tror på att bygga
                        långsiktiga relationer med våra kunder genom att erbjuda
                        exceptionell service och support.
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                        <i className="fas fa-medal "></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-blueGray-500">
                        <strong>Kvalitet:</strong> Vi använder endast de bästa
                        materialen för att säkerställa att ditt kapell är
                        hållbart och ser bra ut. Våra material är noggrant
                        utvalda för att tåla de tuffaste väderförhållandena och
                        ge långvarigt skydd för din båt.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>

              <div className="sm:block flex flex-col mt-10"></div>
              <div className="text-center mt-16"></div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
