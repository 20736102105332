import React, { useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import DropdownProducts from "components/Dropdowns/DropdownProducts.js";
import DropdownServices from "components/Dropdowns/DropdownServices.js";
import logo from "assets/img/g10.png";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const navRef = useRef(null);

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNavbarOpen(false);
    }
  };

  const handleScroll = () => {
    setNavbarOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        ref={navRef}
        className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-charcoal-black shadow"
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <RouterLink
              to="/"
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
            >
              <img alt="..." src={logo} className="logo h-auto border-none" />
            </RouterLink>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars text-white"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-lightBlue-500 lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              {/* <ul className="flex flex-col lg:flex-row list-none mr-auto">
                <li className="flex items-center">
                  <a
                    className="hover:text-blueGray-500 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="/"
                  >
                    Startsida
                  </a>
                </li>
              </ul> */}
              <ul className="flex flex-col lg:flex-row list-none mr-auto">
                <li className="flex items-center">
                  <RouterLink
                    to="/om-oss"
                    className="hover:text-blueGray-500 text-white px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                  >
                    Showroom
                  </RouterLink>
                </li>
              </ul>
              <ul className="flex flex-col lg:flex-row list-none mr-auto">
                <li className="flex items-center">
                  <RouterLink
                    to="/om-oss"
                    className="hover:text-blueGray-500 text-white px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                  >
                    Sälja till oss
                  </RouterLink>
                </li>
              </ul>
              <ul className="flex flex-col lg:flex-row list-none mr-auto">
                <li className="flex items-center">
                  <RouterLink
                    to="/om-oss"
                    className="hover:text-blueGray-500 text-white px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                  >
                    Om oss
                  </RouterLink>
                </li>
              </ul>
              {/* <li className="flex items-center">
                <DropdownProducts />
              </li>

              <li className="flex items-center">
                <DropdownServices />
              </li> */}
              {/* <ul className="flex flex-col lg:flex-row list-none mr-auto">
                <li className="flex items-center">
                  <ScrollLink
                    to="contact-section"
                    smooth={true}
                    duration={500}
                    className="hover:text-blueGray-500 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
                  >
                    Kontakt
                  </ScrollLink>
                </li>
              </ul> */}
              <li className="flex items-center">
                <a
                  className="hover:text-white text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.instagram.com/tbkapell?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  target="_blank"
                >
                  <i className="text-white fab fa-instagram text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
